import React from 'react'
import { Breadcrumb } from 'react-bootstrap';
import { Link } from "gatsby";

export default function BreadCrumb(props) {
  if (props && props.items) {
    return (
      <BreadCrumb>
        {props.items.map((i, index) => {
          if (i === "/") {
            return <div className="divider text-light" key={index}> / </div>;
          } else if (i.to) {
            return (
              <Link className="active section" to={i.to} key={index}>
                <strong>{i.title}</strong>
              </Link>
            );
          } else {
            return (
              <div className="section text-light" key={index} style={{display: 'flex', alignItems: 'center'}}>
                <h2 style={{ fontSize: 15, marginBottom: 0, fontWeight: '400'}}>
                  {props && props.isLoading ? "Cargando..." : i.title}
                </h2>
              </div>
            );
          }
        })}
      </BreadCrumb>
    );
  }

  return (
    <div className="ui breadcrumb" style={{ backgroundColor: "transparent" }}>
      {props.children}
    </div>
  )
}

export function BreadCumbCar({ title, to, car, url }) {
  const items = [
    {title: "Catálogo", to: `${url}`},
    "/",
    {title: car ? `${car.nombre} ${car.year}` : ""}
  ];

  return (
    <BreadCrumb items={items} />
  )
}

export function BreadCrumbBlogs({title, marca}){
  return(
    <Breadcrumb className='breadCrumBlog' style={{backgroundColor: '#e9ecef', width: '100%', display: 'flex', alignItems: 'center'}}>
      <Breadcrumb.Item><Link to="/">Inicio</Link></Breadcrumb.Item>
      <Breadcrumb.Item><Link to={`/rivero/blogs/`}>Blog</Link></Breadcrumb.Item>
      <Breadcrumb.Item active>{title}</Breadcrumb.Item>
    </Breadcrumb>
  )
}